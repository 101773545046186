/* eslint-disable import/no-duplicates */
/* eslint-disable no-unreachable */
import axios from 'src/utils/axios';

const getPageUser = async (subdomain) => {

    const result = await axios.get(`/page/${subdomain}`)

    if (result.status === 200) {
        if (result?.data?.status === 'success' && result?.data?.user) {
            console.log(result?.data)
            return result?.data?.user
        }

        return null

    }

    return null
}

export default getPageUser