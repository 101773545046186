/* eslint-disable import/no-duplicates */
/* eslint-disable no-unreachable */
import { ca } from 'date-fns/locale';
import axios from 'src/utils/axios';

export const send = async (phone) => {

    try {
        const result = await axios.post(`/otp/send`, {
            phoneNumber: phone,
            source: 'subdomain'
        })

        if (result.status === 200) {
            if (result?.data?.status === 'success') {
                return true
            }

            return false

        }

        return false
    }
    catch (err) {
        return false
    }
}

export const verify = async (code) => {

    try {
        const result = await axios.post(`/otp/verify`, {
            code,
            source: 'subdomain'

        })

        console.log(result)

        if (result.status === 200) {

            console.log(result?.data)
            console.log(result?.data?.status)

            if (result?.data?.status === 'Success') {
                return true
            }

            return false

        }

        return false
    }
    catch (err) {
        console.log(err)
        return false
    }
}

