import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { getPageUser } from 'src/api/page'


const usePageUser = create(
    persist(
        (set) => ({
            user: {},
            // setPage: async (subdomain) => {

            //     window.localStorage.clear()

            //     console.log('subdomain => ', subdomain)
            //     const result = await getPageUser(subdomain)

            //     if (result !== null) {
            //         set({ user: result })
            //     }
            // },
            setPage: (result) => set(state => ({ user: result })),
            setPageClean: () => {
                set({ user: {} })
            }
        }),
        {
            name: 'user-page',
            getStorage: () => localStorage,
            onRehydrateStorage: () => (state) => {
                if (!state) {
                    return {
                        user: {}
                    };
                }
                return state;
            },
        }
    )
)

export default usePageUser