import { useEffect } from "react"
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { useAnalytics } from "use-analytics"
import { AuthConsumer } from 'src/auth/context/jwt';
// layouts
import MainLayout from 'src/layouts/main';
// config
import moment from 'moment';
import { SALESRUSHAPI } from 'src/config-global';
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';




// ----------------------------------------------------------------------

export default function Router() {

  const location = useLocation()
  const analytics = useAnalytics()

  useEffect(() => {
    // send page view on route change

    analytics.page(({ payload }) => {
      if (payload.type === 'pageEnd') {

        const subdomain = window.location.host.split(".");

        const data_ = {
          url: payload.properties.url,
          page: payload.properties.path,
          subdomain: subdomain[0],
          f_date: moment().format('MM-YYYY'),
          date: moment().format()
        };

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data_)
        };
        fetch(`${SALESRUSHAPI.api_url}/page_loads`, options)
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(error => console.error(error));
      }

    });
  }, [location, analytics])

  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <AuthConsumer>
          <MainLayout>
            <HomePage />
          </MainLayout>
        </AuthConsumer>
      ),
    },

    // Auth routes
    // ...authRoutes,
    // ...authDemoRoutes,

    // Dashboard routes
    // ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
