// routes
import { paths } from 'src/routes/paths';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const homeMenu = [{
  title: 'Home',
  icon: <Iconify icon="solar:home-2-bold-duotone" />,
  path: '/',
}]

export const moreMenu = [
  {
    title: 'Listings',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.listings.homes,
  },
  {
    title: 'Learn',
    path: '/learn',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
  },
]

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'Buy',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/landing/buy-a-property',
  },
  {
    title: 'Sell',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/landing/sell-a-property',
  },
  {
    title: 'Listings',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.listings.homes,
  },
  {
    title: 'Learn',
    path: '/learn',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
  },
];
