/* eslint-disable no-else-return */
/* eslint-disable import/no-duplicates */
import axios from 'src/utils/axios';
import { fetcher, endpoints } from 'src/utils/axios';


const leads = async (id, body) => {

    const result = await axios.post(`/leads/${id}`, body)

    if (result.status === 200) {
        if (result?.data?.status === 'success') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export default leads