/* eslint-disable react-hooks/exhaustive-deps */
// i18n
import 'src/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// redux
import ReduxProvider from 'src/redux/redux-provider';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import Analytics from 'analytics'
import { AnalyticsProvider } from "use-analytics"
// auth
import { AuthProvider } from 'src/auth/context/jwt';
import usePageUser from 'src/store/page-user'
import { getPageUser } from 'src/api/page'
import { useEffect, useState } from 'react';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';

// ----------------------------------------------------------------------

const analytics = Analytics({
  app: 'salesrushia',
  version: 100,
  debug: true,
})


export default function App() {

  const [subdomain] = useState(window.location.host.split("."));
  const { setPage } = usePageUser()

  const charAt = `

  ░░░    ░░░ 
  ▒▒▒▒  ▒▒▒▒ 
  ▒▒ ▒▒▒▒ ▒▒ 
  ▓▓  ▓▓  ▓▓ 
  ██      ██ 
  
  `;

  useEffect(() => {
    const init = async () => {

      console.log(process.env)

      if (process.env.NODE_ENV === 'production' && subdomain.length === 2) {
        window.location.href = 'https://salesrush.ai'
        return
      }
      if (process.env.NODE_ENV === 'development' && subdomain.length === 1) {
        window.location.href = 'https://salesrush.ai'
        return
      }


      const result = await getPageUser(subdomain[0])
      if (result !== null) {
        setPage(result)
      }
    }
    init()
  }, [])

  console.info(`%c${charAt}`, 'color: #5BE49B');

  useScrollToTop();

  return (
    <AuthProvider>
      <ReduxProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AnalyticsProvider instance={analytics}>
                    <Router />
                  </AnalyticsProvider>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </AuthProvider>
  );
}
