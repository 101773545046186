/* eslint-disable no-else-return */
import axios, { fetcher, endpoints } from 'src/utils/axios';


const getQuetions = async (id) => {

    const result = await axios.get(`/questions/${id}`)

    if (result.status === 200) {
        if (result?.data?.status === 'success' && result?.data?.questions) {
            return result?.data?.questions
        }
        else {
            return null
        }
    }
    else {
        return null
    }
}

export default getQuetions