
import salesRushApiBaseQuery from "./api";


const salesrushApi = salesRushApiBaseQuery.injectEndpoints({
    endpoints: (build) => ({
        getFormData: build.query({
            query(body) {
                return {
                    url: 'contact',
                    method: 'POST',
                    body // { contact_id }
                }
            }
        }),
        stripeSetupIntent: build.query({
            query(body) {
                return {
                    url: 'stripe/setupintent',
                    method: 'POST',
                    body // { client_id }
                }
            }
        }),
        stripeSetCustomerDefaultPaymentMethod: build.query({
            query(body) {
                return {
                    url: 'stripe/customer/defaultpayment',
                    method: 'POST',
                    body // { customer_id, payment_method_id }
                }
            }
        }),
        stripeListPaymentMethods: build.query({
            query(body) {
                return {
                    url: 'stripe/paymentmethod/list',
                    method: 'POST',
                    body // { customer }
                }
            }
        }),
        stripeUpdateSubscription: build.query({
            query(body) {
                return {
                    url: 'stripe/changesubscription',
                    method: 'POST',
                    body // { subscription_id, sub_item_id, price_id }
                }
            }
        }),
        stripeCancelSubscription: build.query({
            query(body) {
                return {
                    url: 'stripe/cancelsubscription',
                    method: 'POST',
                    body // { subscription_id}
                }
            }
        }),
        stripeReactiveSubscription: build.query({
            query(body) {
                return {
                    url: 'stripe/reactivesubscription',
                    method: 'POST',
                    body // { subscription_id }
                }
            }
        }),
        storeSellForm: build.query({
            query(body) {
                return {
                    url: 'user/sell',
                    method: 'Post',
                    body,
                }
            }
        }),
        storeBuyForm: build.query({
            query(body) {
                return {
                    url: 'client/form/buy',
                    method: 'Post',
                    body
                }
            }
        }),
        storeHomeHunt: build.query({
            query(body) {
                return {
                    url: 'client/form/homehunt',
                    method: 'Post',
                    body
                }
            }
        }),
        register: build.query({
            query(body) {
                return {
                    url: 'auth/register',
                    method: 'POST',
                    body // { user }
                }
            }
        }),
        authValidatePhone: build.query({
            query(body) {
                return {
                    url: 'auth/validate/phone',
                    method: 'POST',
                    body // { cell_phone_number }
                }
            }
        }),
        getUserData: build.query({
            query(body) {
                return {
                    url: 'auth/login',
                    method: 'POST',
                    body // { phone }
                }
            }
        }),
        postValidationPhone: build.query({
            query(body) {
                return {
                    url: 'account/validate/phone',
                    method: 'POST',
                    body // { phone }
                }
            }
        }),
        postValidationEmail: build.query({
            query(body) {
                return {
                    url: 'account/validate/email',
                    method: 'POST',
                    body // { email }
                }
            }
        }),
        postSendPhoneCode: build.query({
            query(body) {
                return {
                    url: 'send/sms',
                    method: 'POST',
                    body // { to: phone, clientID }
                }
            }
        }),
        postSendEmailCode: build.query({
            query(body) {
                return {
                    url: 'send/email',
                    method: 'POST',
                    body // { to: email, clientID }
                }
            }
        }),
        postStoreClient: build.query({
            query(body) {
                return {
                    url: 'client',
                    method: 'POST',
                    body // { lead }
                }
            }
        }),
        postValidationPhoneCode: build.query({
            query(body) {
                return {
                    url: 'validate/code',
                    method: 'POST',
                    body // { code, clientID }
                }
            }
        }),
        getPage: build.query({
            async queryFn(subdomain, _queryApi, _extraOptions, fetchWithBQ) {
                const { data: { user }, error } = await fetchWithBQ(`page/${subdomain}`);
                if (error) return { error };

                console.log('USER => ', user)

                const { data, error: reviews_error } = await fetchWithBQ(`reviews/${user.id}`);


                const { data: data_g, error: gallery_error } = await fetchWithBQ(`gallery/${user.id}`);

                console.log('data_g => ', data_g)

                return { data: { user, reviews: data.reviews, gallery: data_g.gallery || [], error: reviews_error, error_gallery: gallery_error } };

            }
        }),

        getLandingPage: build.query({
            async queryFn(value, _queryApi, _extraOptions, fetchWithBQ) {

                const { data, error } = await fetchWithBQ(`landing/${value.owner}/${value.url}`)

                if (error) return { error };

                console.log(data?.landing, 'DATA REQ')

                return {
                    data: data?.landing
                }


            }
        }),

        getQuestionsPage: build.query({
            async queryFn(landing_id, _queryApi, _extraOptions, fetchWithBQ) {

                const { data, error } = await fetchWithBQ(`questions/${landing_id}`)

                if (error) return { error };

                console.log('questions => ', data)

                return {
                    data: data?.questions
                }


            }
        }),

        getReviews: build.query({
            query(owner) {
                return {
                    url: `reviews/${owner}`,
                }
            }
        }),
        getBlog: build.query({
            query(owner) {
                return {
                    url: `blog/${owner}`,
                }
            }
        }),
        getPost: build.query({
            query(body) {
                return {
                    url: `post/${body.owner}/${body.slug}`,
                }
            }
        }),
        postTrack: build.query({
            query(body) {
                return {
                    url: 'page_loads',
                    method: 'POST',
                    body // { code, clientID }
                }
            }
        }),
    }),
    overrideExisting: false,
});

export const { useLazyPostValidationPhoneQuery,
    useLazyPostValidationEmailQuery,
    useLazyPostStoreClientQuery,
    useLazyPostSendPhoneCodeQuery,
    useLazyPostSendEmailCodeQuery,
    useLazyPostValidationPhoneCodeQuery,
    useLazyGetUserDataQuery,
    useLazyRegisterQuery,
    useStripeSetupIntentQuery,
    useLazyStripeSetupIntentQuery,
    useLazyStripeSetCustomerDefaultPaymentMethodQuery,
    useLazyStripeListPaymentMethodsQuery,
    useLazyStripeUpdateSubscriptionQuery,
    useLazyStripeCancelSubscriptionQuery,
    useLazyStripeReactiveSubscriptionQuery,
    useLazyAuthValidatePhoneQuery,
    useLazyStoreSellFormQuery,
    useLazyStoreBuyFormQuery,
    useLazyStoreHomeHuntQuery,
    useGetPageQuery,
    useGetLandingPageQuery,
    useGetQuestionsPageQuery,
    useGetReviewsQuery,
    useGetBlogQuery,
    useGetPostQuery,
    useLazyPostTrackQuery,
    useGetFormDataQuery,
} = salesrushApi;
