import { findIndex, map } from "lodash";
import moment from "moment";
import { property_types_forms } from "src/sections/listings/property_types";

const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    firstname: "",
    lastname: "",
    typePropertyLookingFor: "",
    locationOrAreaInterested: "",
    bedrooms: "0",
    bathrooms: "0",
    firstTimeHomeBuyer: "",
    militaryService: "",
    preApprovedMortgageLoan: "",
    planToBuy: "",
    textUpdate: "",
    ListPricegt: 0,
    ListPricelt: undefined,
    timeForUpdateAndConsultation: '',
    methodCommunication: '',
    phone: '',
    created: moment().format(),
    owner: '',
    property_types: map(property_types_forms, property => { property.checked = false; return property }),
    wizard: []
}

const newHomeSlice = createSlice({
    name: 'newhome',
    initialState,
    reducers: {
        reset: () => initialState,
        setNewHomeValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        setPropertyTypeValue: (state, action) => {
            const { id } = action.payload;
            const index = findIndex(state.property_types, { id });
            state.property_types[index] = { ...state.property_types[index], checked: !state.property_types[index].checked };
            console.log('state check', state.property_types[index]);
        },
        setWizardValue: (state, action) => {

            if (!action.payload) {
                console.log('here')
                return
            }

            const { question, index, value } = action.payload

            state.wizard.push(action.payload)

            console.log(state.wizard)

        },
    }
});

export const {
    setNewHomeValue,
    reset,
    setPropertyTypeValue,
    setWizardValue
} = newHomeSlice.actions;

export default newHomeSlice.reducer;
