/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { usePathname } from 'src/routes/hook';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import Scrollbar from 'src/components/scrollbar';
//
import Iconify from 'src/components/iconify';
import usePageUser from 'src/store/page-user';
import NavList from './nav-list';

// ----------------------------------------------------------------------

export default function NavMobile({ offsetTop, home, more }) {
  const pathname = usePathname();

  const nav = useBoolean();

  const [menu, setMenu] = useState([])
  const { user, setPageClean } = usePageUser()


  useEffect(() => {
    if (nav.value) {
      nav.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {


    let newMenu = [].concat(home)
    const landings = user?.landings

    console.log('landings => ', landings)

    for (const i in landings) {
      const key = landings[i]
      newMenu.push({
        title: key.page_name,
        icon: <Iconify icon="solar:atom-bold-duotone" />,
        path: `/landing/${key.url_path}`,
      })
    }

    newMenu = newMenu.concat(more)

    console.log(newMenu)

    setMenu(newMenu)

  }, [user])

  return (
    <>
      <IconButton
        onClick={nav.onTrue}
        sx={{
          ml: 1,
          ...(offsetTop && {
            color: 'text.primary',
          }),
        }}
      >
        <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
      </IconButton>

      <Drawer
        open={nav.value}
        onClose={nav.onFalse}
        PaperProps={{
          sx: {
            pb: 5,
            width: 260,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List component="nav" disablePadding>
            {menu.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}

NavMobile.propTypes = {
  home: PropTypes.array,
  more: PropTypes.array,
  offsetTop: PropTypes.bool,
};
